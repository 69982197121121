<template>
  <div class="help-wrap">
    <el-breadcrumb separator="/" class="path">
      <el-breadcrumb-item :to="{ path: '/' }" class="path-home">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/cms/help' }" class="path-help" >精品案例</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="help" v-loading="loading" style="width:1210px;border: 1px solid #f1f1ff;padding-bottom: 0px;">
      <div style="font-size:16px;font-weight:bold;width:20%;text-align:center;line-height:80px;margin-left:30px">案例类型</div>
      <div v-for="(item, index) in namedata" :key="index" class="menu" style="border:none;min-height:80px;display:flex;align-items: center;font-size:16px;justify-content: space-around;">
          
          <div class="hoversss" :class="item.flag?'item-flag':''" @click="categoryTopItemClick(item.category_id)">{{item.category_name}}</div>
        <!-- <div class="title">帮助列表</div>
        <div class="item" v-for="(item, index) in helpList" :key="index">
          <div
            :class="currentId == item.class_id ? 'active item-name' : 'item-name'"
            @click="menuOther(item.class_id)"
          >{{ item.class_name }}</div>
        </div> -->
      </div>
      <div class="list-other">
        <transition name="slide">
          <router-view />
        </transition>
      </div>
    </div>
    <div style="margin-top:90px;display:flex;justify-content: space-between;margin-bottom:80px">
      <div>
        <p style="color:#414141;font-size: 30px;font-weight: bold;">{{title}}</p>
        <p style="color:#222222;font-size: 30px;font-weight: bold;">{{title2}}</p>
        <div style="height:2px;width:116px;background-color: #000;margin-top:45px;"></div>
        <p style="width:470px;margin-top:50px;">{{description}}</p>
        <!-- <router-link to="/cms/help"><button class="hovers" style="width:164px;height:38px;margin-top:138px;background:red;border: none;color:#fff;" @click="checkmore">查看更多 >></button></router-link>   -->
      </div>

      <!-- banner -->
      <div>
        <div style="height:477px;width:637px;">
          <el-carousel indicator-position="" height="430px" arrow="hover" v-loading="loading" @change="handleChange2" :autoplay="true">
            <el-carousel-item v-for="(item, index) in img" :key="index">
              <el-image style="height:477px;width:637px;" :src="item" fit="cover" @click="joinMore" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>  
      <!-- banner end -->
    </div>
    <div style="height:auto;">
      <div style="display:flex;flex-wrap: wrap;justify-content: space-between;margin-bottom:50px;">

        <div class="sonssss" v-for="(item, index) in productImg" :key="index" > 
          <viewer :images="productImg" :toolbar="false" >
            <div style="width:80%;height:233px;margin:auto;margin-top: 25px;text-align: center;">
                <img :src="item.image" alt="" style="height:100%;cursor: pointer;">
            </div>
          </viewer>  
          <p style="text-align:center;font-size:24px;font-weight:bold;">{{item.name}}</p>
        </div>

      </div>
       

    </div>

    <div>
      <!-- <viewer :images="signImages"> -->
        <!-- signImages一定要一个数组，否则报错 -->
        <!-- <img
          v-for="(src,index) in signImages"
          :src="src"
          :key="index"
          width="500px"
          >
      </viewer> -->
    </div>

  </div>
</template>

<script>
import { helpList } from "@/api/cms/help";
export default {
  name: "help",
  components: {},
  data: () => {
    return {
      // signImages: [
      //   'https://ss1.baidu.com/-4o3dSag_xI4khGko9WTAnF6hhy/image/h%3D300/sign=3d9fc10fa3014c08063b2ea53a7a025b/359b033b5bb5c9eac1754f45df39b6003bf3b396.jpg',
      //   'https://ss0.baidu.com/94o3dSag_xI4khGko9WTAnF6hhy/image/h%3D300/sign=b38f3fc35b0fd9f9bf175369152cd42b/9a504fc2d5628535bdaac29e9aef76c6a6ef63c2.jpg',
      //   'https://ss1.baidu.com/-4o3dSag_xI4khGko9WTAnF6hhy/image/h%3D300/sign=7ecc7f20ae0f4bfb93d09854334e788f/10dfa9ec8a1363279e1ed28c9b8fa0ec09fac79a.jpg'
      // ],
      namedata:[],
      helpList: [],
      currentId: 0,
      loading: true,
      description:"",
      title2:"",
      title:"",
      img:[],
      category_id:32,
      productImg:[]


    };
  },
  created() {
    
    this.getInfo();
    var _this=this;
    _this.$ajax.post(
      `https://tengpaidoor.jiajushe.com.cn/api/Demo/anli`,
      _this.$qs.parse({
        // id:"32"
      })
    )
    .then(e=> {
      // let data=res.data.data;
      _this.namedata=e.data
      _this.img=e.data[0].image
      _this.description=e.data[0].description
      _this.title=e.data[0].title
      _this.title2=e.data[0].title2
      // console.log("sdfdsfgdfgfdgdfg",e)
    })
    .catch(error=>{
      console.log(error)
    })


    // 案例列表
    _this.$ajax.post(
      `https://tengpaidoor.jiajushe.com.cn/api/Demo/anliliebiao`,
      _this.$qs.parse({
        id:_this.category_id
      })
    )
    .then(e=> {
      // // let data=res.data.data;
      // _this.namedata=e.data
      // _this.img=e.data[0].image
      // _this.description=e.data[0].description
      // _this.title=e.data[0].title
      // _this.title2=e.data[0].title2
      _this.productImg=e.data
      console.log("fdhgdhdfhdfhgfdgfd",e.data)
    })
    .catch(error=>{
      console.log(error)
    })
  },
  methods: {
    categoryTopItemClick(itemID){
      var _this=this;
        
      _this.namedata.forEach((item,index,arr)=>{
        item.flag = false
        if(itemID == item.category_id){
          item.flag = true
          // that.topId=item.flag
          _this.category_id=itemID
          // uni.setStorageSync("fid",item.id)
        }
				_this[index] = item
      })

      _this.$ajax.post(
        `https://tengpaidoor.jiajushe.com.cn/api/Demo/anli`,
        _this.$qs.parse({
          id:_this.category_id
        })
      )
      .then(e=> {
        // let data=res.data.data;
        // _this.namedata=e.data
        _this.img=e.data[0].image
        _this.description=e.data[0].description
        _this.title=e.data[0].title
        _this.title2=e.data[0].title2
        // console.log("sdfdsfgdfgfdgdfg",e)
      })
      .catch(error=>{
        console.log(error)
      })

      // 案例列表
      _this.$ajax.post(
        `https://tengpaidoor.jiajushe.com.cn/api/Demo/anliliebiao`,
        _this.$qs.parse({
          id:_this.category_id
        })
      )
      .then(e=> {
        // // let data=res.data.data;
        // _this.namedata=e.data
        // _this.img=e.data[0].image
        // _this.description=e.data[0].description
        // _this.title=e.data[0].title
        // _this.title2=e.data[0].title2
        _this.productImg=e.data
        console.log("fdhgdhdfhdfhgfdgfd",e.data)
      })
      .catch(error=>{
        console.log(error)
      })
    },
    checkmore(){},
    handleChange2(){},
    joinMore(){},
    menuOther(id) {
      this.currentId = id;
      this.$router.push({ path: "/cms/help/listother-" + id });
    },
    getInfo() {
      helpList({
        app_module: "admin"
      })
        .then(res => {
          if (res.code == 0 && res.data.length > 0) {
            this.$router.push({
              path: "/cms/help/listother-" + res.data[0].class_id
            });
            this.currentId = res.data[0].class_id;
            this.helpList = res.data;
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.sonssss{background-color: #F5F5F5;width:380.5px;height:313px;margin-top: 30px;}
.sonssss:last-child{margin-right: 0;}
// .el-main{width: 100px!important;}
.item-flag{color: red;}
.hoversss{cursor: pointer;}


.help-wrap{
    background: #ffffff;
    .path{
        padding: 15px 0;
    }
}
.help {
  display: flex;
  padding-bottom: 20px;
  .menu {
    width: 210px;
    min-height: 300px;
    background: #ffffff;
    border: 1px solid #f1f1ff;
    flex-shrink: 0;
    .title {
      padding-left: 16px;
        background: #f8f8f8;
        font-size: $ns-font-size-base;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        color: #666666;
    }
    .item-name {
      font-size: $ns-font-size-base;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 40px;
      border-top: 1px solid #f1f1f1;
      padding-left: 25px;
      padding-right: 10px;
      height: 40px;
      background: #ffffff;
      color: #666666;
      &:hover {
        color: $base-color;
      }
    }
    .active {
      color: $base-color;
    }
  }
}
.list-other {
  margin-left: 20px;
  width: 80%;
}
</style>
